import React, { useEffect, useState } from 'react';
import {
  SButtonLightGreen,
  SButtonLightGrey,
} from '../../../../styledComponents/Buttons';
import ConditionsHeader from './CardsWorkDoc/components/ConditionsHeader';
import { Divider } from '../../../../styledComponents/Dividers';
import InputMask from 'react-input-mask';
import './KadriCards.css';
import { InputForm } from '../../../../styledComponents/Inputs';
import { Formik } from 'formik';
import { connect, useDispatch, useSelector } from 'react-redux';
import { acceptHoliday } from '../../../../redux/workers/workers-thunks';
import { useAlert } from 'react-alert';
import datePicker from '../../../../assets/datePicker.svg';
import Calendar from 'react-calendar';
import getDateAndTime from '../../../../utilits/getDateAndTime';
import { getSettings } from '../../../../redux/settings/settings-thunks';
import DatePickerFragment from '../../../../components/datePicker/datePicker';
import moment from 'moment';
import loadCurrentWorker from '../../../../redux/actions/currentWorker/loadCurrentWorker';
import { deletedDocument } from '../../../../redux/documentOld/document-thunks';
import { setModalInfo } from '../../../../redux/modalInfo/modal-actions';

const parseToValidDate = (dateString) => {
  const m = moment(dateString, 'DD.MM.YYYY', true);
  return m.isValid() ? m.toDate() : null;
};

export let KadriCardsHoliday = ({
  toggleHoliday,
  acceptHoliday,
  setTypeId,
  openEditWorkerModal,
  setTypeModal,
  toggleModalList,
  setIndexTab,
  employerId,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const alert = useAlert();
  const id = window.location.pathname.slice(
    window.location.pathname.lastIndexOf('/') + 1
  );
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal.info);

  const alertFunc = (message, type) => {
    alert.show(message, { type });
    setIsFetching(false);
  };

  useEffect(() => {
    (async () => {
      try {
        await props.getSettings(id);
      } catch (error) {
        console.error('Помилка отримання налаштувань:', error);
        alert.show('Сталася помилка при отриманні налаштувань', {
          type: 'error',
        });
      }
    })();
  }, [id]);

  return (
    <>
      {props.settings && (
        <Formik
          initialValues={{
            typeOfHoliday: modal?.typeText ?? '',
            beginDate: modal?.exp?.start
              ? parseToValidDate(modal?.exp?.start)
              : null,
            periudStart: '',
            periudEnd: '',
            dateNakaz: modal?.dateNakaz
              ? parseToValidDate(modal.dateNakaz)
              : modal?.dataaccept
              ? parseToValidDate(modal.dataaccept)
              : null,
            endDate: modal?.exp?.end ? parseToValidDate(modal.exp?.end) : null,
            order:
              modal?.nomer ??
              (props.settings?.number1 !== -1
                ? props.settings?.number1 + 1
                : ''),
          }}
          onSubmit={async (values) => {
            try {
              setIsFetching(true);
              const body = {
                isEdit: modal?.isEdit,
                id: modal?.employerId ?? employerId,
                daystart: moment(values.beginDate).format('DD.MM.YYYY'),
                dayend: moment(values.endDate).format('DD.MM.YYYY'),
                dateNakaz: moment(values.dateNakaz).format('DD.MM.YYYY'),
                periudEnd: moment(values.periudEnd).format('DD.MM.YYYY'),
                periudStart: moment(values.periudStart).format('DD.MM.YYYY'),
                number: values.order,
                type: values.typeOfHoliday,
              };
              const fileId = await acceptHoliday(
                body,
                alertFunc,
                toggleHoliday
              );
              if (!modal) {
                openEditWorkerModal(employerId);
                setTypeModal('Пакет документів на відпустку');
                setTypeId(fileId);
                setIndexTab();
                toggleHoliday();
              } else {
                dispatch(deletedDocument(modal.documentId, modal.year));
                dispatch(setModalInfo(null));
                toggleModalList.holiday();
              }
            } catch (error) {
              console.error('Помилка обробки відпустки:', error);
              alert.show('Сталася помилка при обробці відпустки', {
                type: 'error',
              });
            } finally {
              setIsFetching(false);
            }
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => {
            const type_vac = 'відпустку без збереження заробітної плати';
            let isValid =
              values.typeOfHoliday &&
              values.beginDate &&
              values.endDate &&
              values.dateNakaz &&
              (values.typeOfHoliday === type_vac
                ? true
                : values.periudStart && values.periudEnd) &&
              values.order;

            const handleSafeDateChange = (field, date) => {
              try {
                if (date) {
                  date.setHours(12);
                  setFieldValue(field, date);
                } else {
                  setFieldValue(field, null);
                }
              } catch (error) {
                console.error(`Помилка при зміні дати для ${field}:`, error);
                alert.show(`Сталася помилка при зміні дати для ${field}`, {
                  type: 'error',
                });
              }
            };

            const iconGray = (
              <div
                className="item__input__check"
                type="button"
                style={{ paddingTop: '8px', marginBottom: '2px' }}
              >
                <span>
                  <img
                    src={datePicker}
                    alt="date icon"
                  />
                </span>
              </div>
            );

            return (
              <>
                {modal && (
                  <div
                    className="bg__layer"
                    style={{ zIndex: 999 }}
                  />
                )}
                <div className="conditions__workdoc">
                  <ConditionsHeader
                    toggle={() => {
                      try {
                        toggleHoliday();
                        if (modal) {
                          dispatch(setModalInfo(null));
                        }
                      } catch (error) {
                        console.error(
                          'Помилка при зміні модального вікна:',
                          error
                        );
                        alert.show(
                          'Сталася помилка при зміні модального вікна',
                          { type: 'error' }
                        );
                      }
                    }}
                  >
                    Оформлення відпустки
                  </ConditionsHeader>
                  <Divider />
                  <div className="conditions__content">
                    <div className="conditions__content-left">
                      <p>Вид відпустки</p>
                      <p>Період відпустки</p>
                      {values.typeOfHoliday !== type_vac && <p>За період</p>}
                      <p>Наказ №</p>
                      <p>Дата наказу</p>
                    </div>
                    <div className="conditions__content-right">
                      <form
                        className="conditions__form"
                        action="#"
                      >
                        <div className="conditions__row">
                          <select
                            id="typeOfHoliday"
                            value={values.typeOfHoliday}
                            onChange={handleChange}
                            style={{ height: '32px', width: 314 }}
                            className="conditions__select-input"
                          >
                            <option
                              value=""
                              hidden
                              disabled
                            >
                              Виберіть зі списку
                            </option>
                            <option value="щорічну основну відпустку">
                              щорічна основна відпустка
                            </option>
                            <option value="додаткову відпустку у зв'язку з навчанням">
                              додаткова відпустка у зв'язку з навчанням
                            </option>
                            <option value="відпустку у зв'язку з вагітністю та пологами">
                              відпустка у зв'язку з вагітністю та пологами
                            </option>
                            <option value="відпустку для догляду за дитиною до досягнення нею трирічного віку">
                              відпустка для догляду за дитиною до досягнення нею
                              трирічного віку
                            </option>
                            <option value="відпустку без збереження заробітної плати">
                              відпустка без збереження заробітної плати
                            </option>
                          </select>
                        </div>
                        <div className="conditions__row">
                          <div className="conditions__calendar">
                            <DatePickerFragment
                              placeholder="Початок"
                              disabled={isFetching}
                              selected={values.beginDate}
                              dateFormat="dd.MM.yyyy"
                              IconGray={iconGray}
                              onChange={(date) =>
                                handleSafeDateChange('beginDate', date)
                              }
                            />
                          </div>
                          <p>-</p>
                          <div className="conditions__calendar">
                            <DatePickerFragment
                              placeholder="Закінчення"
                              disabled={isFetching}
                              selected={values.endDate}
                              dateFormat="dd.MM.yyyy"
                              IconGray={iconGray}
                              onChange={(date) =>
                                handleSafeDateChange('endDate', date)
                              }
                            />
                          </div>
                        </div>
                        {values.typeOfHoliday !== type_vac && (
                          <div className="conditions__row">
                            <div className="conditions__calendar">
                              <DatePickerFragment
                                placeholder="Початок"
                                disabled={isFetching}
                                selected={values.periudStart}
                                dateFormat="dd.MM.yyyy"
                                IconGray={iconGray}
                                onChange={(date) =>
                                  handleSafeDateChange('periudStart', date)
                                }
                              />
                            </div>
                            <p>-</p>
                            <div className="conditions__calendar">
                              <DatePickerFragment
                                placeholder="Закінчення"
                                disabled={isFetching}
                                selected={values.periudEnd}
                                dateFormat="dd.MM.yyyy"
                                IconGray={iconGray}
                                onChange={(date) =>
                                  handleSafeDateChange('periudEnd', date)
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div className="conditions__row">
                          <InputForm
                            type="text"
                            tabIndex="6"
                            className="conditions__text-input"
                            id="order"
                            value={values.order}
                            onChange={handleChange}
                            style={{
                              backgroundColor:
                                props.settings?.number1 !== -1
                                  ? 'rgb(242, 242, 242)'
                                  : '',
                            }}
                            disabled={props.settings?.number1 !== -1}
                          />
                        </div>
                        <div className="conditions__row">
                          <div className="conditions__calendar">
                            <DatePickerFragment
                              placeholder="Дата"
                              disabled={isFetching}
                              selected={values.dateNakaz}
                              dateFormat="dd.MM.yyyy"
                              IconGray={iconGray}
                              onChange={(date) =>
                                handleSafeDateChange('dateNakaz', date)
                              }
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="conditions__buttons">
                    {!isFetching && isValid ? (
                      <SButtonLightGreen
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Зберегти
                      </SButtonLightGreen>
                    ) : (
                      <SButtonLightGrey>Зберегти</SButtonLightGrey>
                    )}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settingsReducer.settings,
});

KadriCardsHoliday = connect(mapStateToProps, {
  acceptHoliday,
  getSettings,
})(KadriCardsHoliday);
