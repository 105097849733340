import {
  CREATE_OBJECT,
  DELETE_OBJECT,
  GET_MY_OBJECT,
  GET_ONE_DOCUMENT,
  GOOGLE_FILE_DELETE,
  GOOGLE_FILE_DOWNLOAD,
  GOOGLE_FILE_VIEW,
  SET_MONTH_OBJECT,
  UPDATE_OBJECT,
} from '../constants/urls';
import { getCookie } from '../utilits/cookies';
import axios from 'axios';
import instance from '../redux/instance';
import regions from '../constants/regions';

export const getObjects = async () => {
  const token = getCookie('token');
  const response = await fetch(GET_MY_OBJECT, {
    method: 'POST',
    headers: {
      Authorization: token,
    },
  });
  return await response.json();
};
// documents
export const uploadDocuments = async (files, id, address, alert) => {
  let city = address.city.split(', ');

  if (city.length === 4) {
    city = `${city[2]}/${/[0-9.,:]/.test(city[1]) ? city[0] : city[1]}`;
  } else if (city.length === 3) {
    city = `${city[1]}/${city[0]}`;
  }

  let pathBasket = `object-of-activity/${
    regions[address.region].name
  }/${city}_${address.house}/`;
  try {
    let count = 1;
    for (let file of files) {
      let formData = new FormData();

      formData.append('filename', file.file.name);
      formData.append('sampleFile', file.file);
      formData.append('indexId', id);
      formData.append('path', pathBasket);
      formData.append('type', file.type);
      const response = await instance.post('/googleStorage/upload', formData, {
        headers: {
          'Content-Type': 'Multipart/form-data',
        },
      });
      if (response.status !== 200) {
        throw Error(response.data || 'Щось пішло не так');
      } else {
        alert(`Документ №${count++} доданий`, { type: 'success' });
        await getDocuments(id);
      }
    }
  } catch (e) {
    alert(e.message, { type: 'error' });
  }
};

export const deleteFileObject = async (id, path, filename, elId) => {
  const token = getCookie('token');
  const res = await axios.post(
    GOOGLE_FILE_DELETE,
    { id: id, elId: elId, path: path, filename: filename },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  return res.data;
};
export const downloadFileObject = async (id, path, filename) => {
  const token = getCookie('token');
  const res = await axios.post(
    GOOGLE_FILE_DOWNLOAD,
    { id: id, path: path, filename: filename },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  return res.data;
};
export const viewFilesObject = async (id, path, filename) => {
  const token = getCookie('token');
  const res = await axios.post(
    GOOGLE_FILE_VIEW,
    { id: id, path: path, filename: filename },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  return res.data;
};

export const getDocuments = async (id) => {
  try {
    if (!id) {
      throw new Error('Не вказано ID для отримання документів');
    }

    const token = getCookie('token');
    const res = await axios.post(
      GET_ONE_DOCUMENT,
      { indexId: id },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    );

    return res.data;
  } catch (error) {
    console.error('Помилка при отриманні документів:', error);
    return { error: error.message || 'Помилка при отриманні документів' };
  }
};

export const createObject = async (data) => {
  const token = getCookie('token');
  const response = await fetch(CREATE_OBJECT, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  return await response.json();
};

export const updateObject = async (id, update) => {
  const token = getCookie('token');
  const response = await fetch(UPDATE_OBJECT, {
    method: 'POST',
    body: JSON.stringify({ id, update }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  return await response.json();
};
export const updateMonthProlongation = async (id) => {
  const token = getCookie('token');

  const res = await axios.post(
    SET_MONTH_OBJECT,
    { id: id },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  return res.data;
};
export const deleteObject = async (id) => {
  const token = getCookie('token');
  const response = await fetch(DELETE_OBJECT, {
    method: 'POST',
    body: JSON.stringify({ id }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
  return await response.json();
};
