import { UPDATE_OBJECT } from '../../../constants/urls';
import instance from '../../instance';
import showAlert from '../alerts/showAlert';
import loadObjects from './loadObjects';

const updateObjects = (id, update, setLoading) => async (dispatch) => {
  try {
    if (!id) {
      throw new Error("Не вказано ID об'єкта для оновлення");
    }

    let normalizedPositions = [];
    if (Array.isArray(update.position)) {
      normalizedPositions = update.position
        .map((pos) => {
          if (
            pos &&
            typeof pos === 'object' &&
            pos.position &&
            pos.position.label &&
            pos.position.value
          ) {
            return pos;
          }

          if (pos && typeof pos === 'object') {
            return {
              position: {
                label: pos.position?.label || 'Не вказано',
                value:
                  pos.position?.value ||
                  pos.position?.id ||
                  pos._id ||
                  'unknown',
              },
              quality: pos.quality || 0,
            };
          }

          return null;
        })
        .filter(Boolean);
    }

    const completeUpdate = {
      ...update,
      position: normalizedPositions,
    };

    if (typeof setLoading === 'function') {
      setLoading(true);
    }

    const response = await instance.post(UPDATE_OBJECT, {
      id,
      update: completeUpdate,
    });

    if (response.data && response.data.success) {
      await dispatch(loadObjects());
    }

    if (typeof setLoading === 'function') {
      setLoading(false);
    }

    return response;
  } catch (error) {
    console.error("Помилка при оновленні об'єкта:", error);

    if (typeof setLoading === 'function') {
      setLoading(false);
    }

    dispatch(
      showAlert(
        error.response?.data?.message ||
          error.message ||
          "Помилка при оновленні об'єкта",
        'error'
      )
    );

    return {
      data: {
        success: false,
        message:
          error.response?.data?.message ||
          error.message ||
          'Помилка при оновленні',
      },
    };
  }
};

export default updateObjects;
