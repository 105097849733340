import instance from '../instance';
import {
  setAllDocuments,
  setPositions,
  setVacations,
  setWorkDays,
  setZpInfo,
} from './cadrDocuments-actions';
import { downloadDocument } from '../workers/workers-thunks';

export const getPositions = (id) => async (dispatch) => {
  try {
    const response = await instance.post('/employer/getMyPosadas', { id });
    if (response.status === 200) {
      dispatch(setPositions(response.data.posada));
    }
  } catch (e) {
    console.error('getPositions :: error >>>', e);
  }
};

export const addPosition =
  (body, alert, closeModal, editPositionId) => async (dispatch) => {
    console.log(body);
    try {
      const response = await instance.post(
        editPositionId ? '/employer/updPosada' : '/employer/addPosada',
        editPositionId ? { ...body, id: editPositionId } : body
      );
      if (response.status === 200) {
        alert(response.data.message, 'success');
        dispatch(getPositions(body.id));
        closeModal();
      } else {
        throw Error(response.data.message || 'Щось пішло не так');
      }
    } catch (e) {
      console.error('addPosition :: error >>>', e);
      alert(e.message, 'error');
    }
  };

export const deletePosition = (alert, id) => async (dispatch, getState) => {
  const { positions } = getState().cadrDocumentsReducer;
  try {
    const response = await instance.post('/employer/deletePosada', { id });
    if (response.status === 200) {
      alert(response.data.message, 'success');
      dispatch(setPositions([...positions].filter((p) => p._id !== id)));
    } else {
      throw Error(response.data.message || 'Щось пішло не так');
    }
  } catch (e) {
    console.error('deletePosition :: error >>>', e);
    alert(e.message, 'error');
  }
};

// work days
export const getWorkDays = (year, month) => async (dispatch, getState) => {
  try {
    const { currentEmployer } = getState().employersReducer;
    const response = await instance.post('/employer/getWorkDays', {
      workerId: currentEmployer._id,
      year: year,
      month: month,
    });
    dispatch(setWorkDays(response.data));
  } catch (error) {
    console.error('getWorkDays :: error >>>', error);
  }
};

export const setWorkDay = (body) => async (dispatch, getState) => {
  try {
    const { currentEmployer } = getState().employersReducer;
    await instance.post('/employer/setWorkDay', {
      ...body,
      workerId: currentEmployer._id,
    });
    dispatch(getWorkDays(body.year, body.month));
  } catch (error) {
    console.error('setWorkDay :: error >>>', error);
  }
};

// vacations
export const getVacations =
  (currId = null, year) =>
  async (dispatch, getState) => {
    try {
      const { currentEmployer } = getState().employersReducer;
      const response = await instance.post('/employer/getAllFrees', {
        workerid: currId ? currId : currentEmployer._id,
        year: year,
      });
      dispatch(setVacations(response.data));
    } catch (error) {
      console.error('getVacations :: error >>>', error);
    }
  };

export const getDocumentsJournal = (year) => async (dispatch, getState) => {
  try {
    const { currentEmployer } = getState().employersReducer;
    const response = await instance.post('/employer/getJournalDocs', {
      workerid: currentEmployer._id,
      year: year,
    });
    dispatch(setAllDocuments(response.data));
  } catch (error) {
    console.error('getDocumentsJournal :: error >>>', error);
  }
};

export const downloadAllDocuments =
  (setLoader, year) => async (dispatch, getState) => {
    try {
      const { currentEmployer } = getState().employersReducer;
      setLoader(true);
      const response = await instance.post('/employer/getNakaz', {
        workerId: currentEmployer._id,
        year: year,
      });
      if (response.data.status === 'sucess') {
        setLoader(false);
        dispatch(downloadDocument([response.data.data]));
      }
    } catch (error) {
      console.error('downloadAllDocuments :: error >>>', error);
    }
  };

export const downloadFrees =
  (setLoader, year, date) => async (dispatch, getState) => {
    try {
      const { currentEmployer } = getState().employersReducer;
      setLoader(true);
      const response = await instance.post('/employer/getFreesPdf', {
        workerId: currentEmployer._id,
        year: year,
        date: date,
      });
      if (response.data.status === 'sucess') {
        setLoader(false);
        dispatch(downloadDocument([response.data.data]));
      }
    } catch (error) {
      console.error('downloadFrees :: error >>>', error);
    }
  };

export const getZpInfo =
  (year, month, setLoader) => async (dispatch, getState) => {
    try {
      const { currentEmployer } = getState().employersReducer;
      setLoader(false);
      const response = await instance.post('/employer/getZpHTML', {
        workerid: currentEmployer._id,
        year,
        month,
      });
      setTimeout(() => {
        setLoader(true);
      }, 1000);
      dispatch(setZpInfo(response.data));
    } catch (error) {
      console.error('getZpInfo :: error >>>', error);
      setLoader(true);
      dispatch(setZpInfo([]));
    }
  };

export const updateAvans = (avans, employerId) => async () => {
  try {
    console.log('updateAvans');
    const response = await instance.post('/employer/updateAvans', {
      employerId,
      avans,
    });
    return response.data;
  } catch (error) {
    console.error('updateAvans :: error >>>', error);
    throw error;
  }
};

export const downloadZp = (body) => async (dispatch, getState) => {
  try {
    const { currentEmployer } = getState().employersReducer;
    const response = await instance.post('/employer/getZp', {
      workerid: currentEmployer._id,
      ...body,
    });
    if (response.data) {
      dispatch(downloadDocument([response.data]));
    }
  } catch (error) {
    console.error('downloadZp :: error >>>', error);
  }
};

export const getAllPositions = () => async (dispatch) => {
  try {
    const response = await instance.get('/employer/getAllPosadas');
    if (response.status === 200 && response.data && response.data.posadas) {
      dispatch(setPositions(response.data.posadas));
    } else {
      dispatch(
        setPositions([
          { _id: 'pos1', vacancy: 'Продавець', rate: '1' },
          { _id: 'pos2', vacancy: 'Адміністратор', rate: '1' },
          { _id: 'pos3', vacancy: 'Керуючий', rate: '1' },
          { _id: 'pos4', vacancy: 'Бухгалтер', rate: '1' },
          { _id: 'pos5', vacancy: 'Інспектор з інвентаризації', rate: '1' },
          { _id: 'pos6', vacancy: 'Касир', rate: '1' },
          { _id: 'pos7', vacancy: 'Заступник керуючого', rate: '1' },
        ])
      );
    }
  } catch (error) {
    console.error('getAllPositions :: error >>>', error);
    dispatch(
      setPositions([
        { _id: 'pos1', vacancy: 'Продавець', rate: '1' },
        { _id: 'pos2', vacancy: 'Адміністратор', rate: '1' },
        { _id: 'pos3', vacancy: 'Керуючий', rate: '1' },
        { _id: 'pos4', vacancy: 'Бухгалтер', rate: '1' },
        { _id: 'pos5', vacancy: 'Інспектор з інвентаризації', rate: '1' },
        { _id: 'pos6', vacancy: 'Касир', rate: '1' },
        { _id: 'pos7', vacancy: 'Заступник керуючого', rate: '1' },
      ])
    );
  }
};
