import React, { useCallback, useEffect, useRef, useState } from 'react';

import close from '../../../../../assets/close.svg';

import '../KadriCards.css';
import { KadriPageSpecialQuota } from './KadriPageSpecialQuota';
import KadriPageDocument from './KadriPageDocument';
import { KadriPageHistory } from './KadriPageHistory';
import CadrePagePersonal from './DRAFT/PersonalData/organisms/CadrePagePersonal';
import { useAlert } from 'react-alert';
import { connect, useSelector } from 'react-redux';
import KadriPreviewDocument from './KadriPreviewDocument';

import { setPreviewFile } from '../../../../../redux/workers/worker-actions';
import {
  docTypes,
  PROF_EDUCATION,
  SECOND_EDUCATION,
} from '../../../../../redux/types';
import { Formik, useFormik } from 'formik';
import { store } from '../../../../../index';
import addWorker from '../../../../../redux/actions/workers/addWorker';
import { editSecondaryWorkerInfo } from '../../../../../redux/actions/workers/editSecondaryWorkerInfo';
import PassportModal from '../../../PassportModal';
import phoneValidation from '../../../../../validations/phoneValidation';
import { validationSchema } from './DRAFT/PersonalData/lib/validation/validationSchemas';
import { getSearchEmployerAll } from '../../../../../redux/employers/employers-thunks';
import CadrePageConditionsWorkCard from './cadrePageConditionsWork/CadrePageConditionsWorkCard';
import { getDocKadri } from '../../../../../api/kadriDoc';
import { useConditionsWorkCard } from './cadrePageConditionsWork/hooks';
import { MODAL_ID, TAB_MODAL_ID } from '../../KadriItem';

const formatPhoneForLegacySupport = (phoneNumber) => {
  if (phoneNumber.startsWith('+380') && phoneNumber.length === 13) {
    return phoneNumber
      .replace('+380', '0')
      .replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
  }
  return '';
};

const TabContent = ({
  fullName,
  setIsWorker,
  isWorker,
  typeId,
  typeModal,
  objectId,
  active,
  isAddModal,
  setIsPassport,
  page,
  closeModal,
  id,
  workerId,
  setActive,
  files,
  setFiles,
  openFile,
  isPressedFile,
  educationRows,
  setEducationRows,
  workRows,
  setWorkRows,
  familyRows,
  setFamilyRows,
  isPersonal,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const alert = useAlert();
  const [currentStatus, setCurrentStatus] = useState('');

  const currentWorkerData = useSelector((state) => state.currentWorker);
  const currentEmployerData = useSelector(
    (state) => state.employersReducer.currentEmployer
  );
  const [isEdit, setIsEdit] = useState(false);

  console.log('HERE');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      surname: currentWorkerData?.surname ?? '',
      name: currentWorkerData?.name ?? '',
      patronymic: currentWorkerData?.patronymic ?? '',
      mobilePhone: currentWorkerData?.mobilePhone ?? '',
      resours: currentWorkerData?.resours ?? '',
      methodInvite: currentWorkerData?.methodInvite ?? '',
      workPhoneNumber: currentWorkerData?.workPhoneNumber ?? '',
      noCode: currentWorkerData?.noCode || false,
      RNOKPP: currentWorkerData ? currentWorkerData.RNOKPP : '',
      noCode: currentWorkerData?.RNOKPP === 0 || currentWorkerData?.noCode,

      sex: currentWorkerData?.sex ?? '',
      place: currentWorkerData?.place ?? {
        city: '',
        city_processed: '',
        city_region: '',
        country: '',
        id: null,
        oblast: '',
        region: '',
        street: '',
        street_processed: '',
      },
      birthday: currentWorkerData?.birthday ?? '',
      street: currentWorkerData?.street ?? '',
      posada: currentWorkerData?.postInfo?.posada || '',
      workForm:
        currentWorkerData?.postInfo?.workForm ||
        currentWorkerData?.workForm ||
        '',
      house: currentWorkerData?.house ?? '',
      apartments: currentWorkerData?.apartments ?? '',
      passport: currentWorkerData?.passport?.replace(/\s+/g, '') ?? '',
      authority: currentWorkerData?.authority ?? '',
      dateOfIssue: currentWorkerData?.dateOfIssue ?? '',
      curatorId: currentWorkerData?.curatorId ?? '',
      nationality: currentWorkerData?.nationality ?? 'Україна',
      city: currentWorkerData?.street ?? '',
      passportNumber: currentWorkerData?.passportNumber ?? '',
      selectedPassport: /^\d{9}$/.test(currentWorkerData?.passport)
        ? 'ID_CARD'
        : 'OLD_PASSPORT',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const formattedMobilePhone = formatPhoneForLegacySupport(
        values.mobilePhone
      );
      const formattedWorkPhone = values?.workPhoneNumber
        ? formatPhoneForLegacySupport(values.workPhoneNumber)
        : '';
      const passportValues =
        values.selectedPassport === 'OLD_PASSPORT'
          ? values?.passportSeries + values?.passportNumber
          : values?.passport || values?.idCardNumber;

      const modifiedValues = {
        ...values,
        passport: passportValues,
        mobilePhone: formattedMobilePhone,
        workPhoneNumber: formattedWorkPhone,
        street: values.place.street,
        house: values.house || '1',
        RNOKPP: Number(values?.RNOKPP),
        curatorId: values.curatorId || null,
        _id: employerId || currentEmployerData._id,
        workerId: currentWorkerData?.workerId || id,
        searchId: currentWorkerData?.searchId,
        city: values.street,
        objectId: objectId ? objectId : null,
      };

      try {
        if (!isEdit) {
          store.dispatch(
            addWorker(
              {
                ...modifiedValues,
              },
              alertFunc,
              employerId,
              () => setActive(1)
            )
          ); // just add workers
          return;
        }

        if (isEdit && currentWorkerData) {
          store.dispatch(
            addWorker(
              {
                ...modifiedValues,
                _id: currentWorkerData._id,
                objectId: objectId ? objectId : null,
              },
              alertFunc,
              employerId,
              () => setActive(1),
              isEdit
            )
          ); // edit workers
          closeModal('personEdit');
          return;
        }

        store.dispatch(
          addWorker(
            {
              ...modifiedValues,
              _id: employerId || currentEmployerData._id,
              city: modifiedValues.street,
              objectId: objectId ? objectId : null,
            },
            alertFunc,
            employerId,
            () => setActive(1),
            isEdit
          )
        );
      } catch (error) {
      } finally {
      }
    },
  });
  let { values } = formik;

  const alertFunc = (message, type) => {
    alert.show(message, { type: type });
  };

  const employerId = !isPersonal.check
    ? window.location.pathname.slice(
        window.location.pathname.lastIndexOf('/') + 1
      )
    : isPersonal.id;

  const getEducationValues = () => {
    let educationValues = [];
    let objectValues = currentWorkerData?.education
      ? currentWorkerData.education.map((e, index) => ({
          [`title${educationRows[index]?.id}`]: e.title,
          [`atestat${educationRows[index]?.id}`]: e.atestat,
          [`yearRelease${educationRows[index]?.id}`]: e.yearRelease,
          [`speciality${educationRows[index]?.id}`]: e.speciality,
          [`qualification${educationRows[index]?.id}`]: e.qualification,
          [`power${educationRows[index]?.id}`]: e.power,
        }))
      : [];
    for (let obj of objectValues) {
      educationValues = { ...educationValues, ...obj };
    }
    return educationValues;
  };
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    store.dispatch(getSearchEmployerAll(id));
  }, [id]);

  const getWorkValues = () => {
    let workValues = [];
    let objectValues = currentWorkerData?.works
      ? currentWorkerData.works.map((w, index) => ({
          [`worker${workRows[index]?.id}`]: w.worker,
          [`position${workRows[index]?.id}`]: w.position,
          [`reason${workRows[index]?.id}`]: w.reason,
          [`dateDismissal${workRows[index]?.id}`]: w.dateDismissal,
        }))
      : [];
    for (let obj of objectValues) {
      workValues = { ...workValues, ...obj };
    }
    return workValues;
  };

  const getFamilyValues = () => {
    let familyValues = [];
    let objectValues = currentWorkerData?.family
      ? currentWorkerData.family.map((w, index) => ({
          [`familyMember${familyRows[index]?.id}`]: w.familyMember,
          [`PIB${familyRows[index]?.id}`]: w.PIB,
          [`birthYear${familyRows[index]?.id}`]: w.birthYear,
        }))
      : [];
    for (let obj of objectValues) {
      familyValues = { ...familyValues, ...obj };
    }
    return familyValues;
  };

  useEffect(() => {
    if (currentWorkerData) {
      if (
        Object.entries(getFamilyValues())
          .map(([k, value]) => value)
          .indexOf(currentWorkerData.sex === 'Ж' ? 'чоловік' : 'дружина') !== -1
      ) {
        setCurrentStatus(
          currentWorkerData.sex === 'Ж' ? 'заміжня' : 'одружений'
        );
      } else {
        setCurrentStatus(
          currentWorkerData.sex === 'Ж' ? 'незаміжня' : 'неодружений'
        );
      }
    }
  }, [currentWorkerData]);

  const isAddWorkerDataModal = Boolean(
    useSelector((state) => state.modal?.info === null)
  );

  return (
    <div
      style={{ overflowY: page === 'document' ? 'visible' : 'scroll' }}
      className="tabcontent"
    >
      {!currentWorkerData && isAddWorkerDataModal && (
        <PassportModal
          setIsPassport={setIsPassport}
          setFieldValue={formik.setFieldValue}
        />
      )}

      <Formik
        autoComplete="off"
        initialValues={{
          ...getEducationValues(),
          ...getWorkValues(),
          ...getFamilyValues(),
        }}
        onSubmit={(values) => {
          setIsFetching(true);
          let body = {
            id: currentWorkerData._id,
            education: educationRows.map((e) => ({
              title: values[`title${e.id}`],
              atestat: values[`atestat${e.id}`],
              yearRelease: values[`yearRelease${e.id}`],
              speciality:
                e.type === SECOND_EDUCATION
                  ? values[`speciality${e.id}`]
                  : null,
              qualification:
                e.type === SECOND_EDUCATION
                  ? values[`qualification${e.id}`]
                  : null,
              power: e.type === PROF_EDUCATION ? values[`power${e.id}`] : null,
              type: e.type,
              education: e.education,
            })),
            works: workRows.map((e) => ({
              worker: values[`worker${e.id}`],
              position: values[`position${e.id}`],
              reason: values[`reason${e.id}`],
              dateDismissal: values[`dateDismissal${e.id}`],
            })),
            family: familyRows.map((e) => ({
              familyMember: values[`familyMember${e.id}`],
              PIB: values[`PIB${e.id}`],
              birthYear: values[`birthYear${e.id}`],
            })),
          };
          props.editSecondaryWorkerInfo(alertFunc, body, employerId, () =>
            setActive(2)
          );
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <>
            {page === 'specialQuota' && (
              <KadriPageSpecialQuota
                openNextTab={() => setActive(2)}
                closeModal={closeModal}
                educationRows={educationRows}
                setActive={setActive}
                setEducationRows={setEducationRows}
                workRows={workRows}
                setWorkRows={setWorkRows}
                familyRows={familyRows}
                setFamilyRows={setFamilyRows}
                values={values}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                setIsEdited={setIsEdited}
                isEdited={isEdited}
                currentStatus={currentStatus}
                setCurrentStatus={setCurrentStatus}
              />
            )}
          </>
        )}
      </Formik>

      {page === 'conditionsWork' && <CadrePageConditionsWorkCard />}

      {page === 'personData' && (
        <CadrePagePersonal
          id={id}
          formik={formik}
          currentWorkerData={currentWorkerData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}

      {page === 'document' && (
        <KadriPageDocument
          id={currentWorkerData._id}
          isWorker={isWorker}
          typeModal={typeModal}
          typeId={typeId}
          isPersonal={isPersonal}
          values={values}
          fullName={fullName}
          openNextTab={() => setActive(3)}
          closeModal={closeModal}
        />
      )}

      {page === 'history' && <KadriPageHistory />}
    </div>
  );
};

export let KadriCardsPersonEdit = ({
  currentWorker,
  fullName,
  isWorker,
  objectId,
  typeModal,
  typeId,
  setIsWorker,
  indexTab = null,
  ...props
}) => {
  const alert = useAlert();
  const modal = useRef(null);
  const [files, setFiles] = useState([]);
  const { items, closeModal, screen, isAddModal, isPersonal, workersDisabled } =
    props;
  const id = !isPersonal.check
    ? window.location.pathname.slice(
        window.location.pathname.lastIndexOf('/') + 1
      )
    : props.id;
  const [active, setActive] = React.useState(
    indexTab ? indexTab : screen === true ? 0 : screen
  );
  const [isPressedFile, setIsPressedFile] = useState(false);
  const [isPassport, setIsPassport] = useState(false);
  const [educationRows, setEducationRows] = useState(
    currentWorker?.education
      ? currentWorker.education.map((e, i) => ({
          type: e.type,
          education: e.education,
          id: Date.now() + i,
        }))
      : []
  );
  const [workRows, setWorkRows] = useState(
    currentWorker?.works
      ? currentWorker.works.map((w, i) => ({ id: Date.now() + i }))
      : []
  );
  const [familyRows, setFamilyRows] = useState(
    currentWorker?.family
      ? currentWorker.family.map((f, i) => ({ id: Date.now() + i }))
      : []
  );

  const openTab = (e) => {
    if (currentWorker) setActive(+e.target.dataset.index);
    else {
      if (+e.target.dataset.index < active) {
        setActive(+e.target.dataset.index);
      } else {
        alert.show(
          'Заповніть необхідну інформацію в цьому вікні і натисніть "Далі"',
          { type: 'error' }
        );
      }
    }
  };

  useEffect(() => {
    console.log('indexTab', indexTab);

    setActive(indexTab || 0);
  }, [indexTab]);

  useEffect(() => {
    workersDisabled &&
      modal.current
        .querySelectorAll(
          "select,input:not([type='file']),button:not(.tablinks,.buttonDisabled,.document_button_add,.submenu__dropdown__btn)"
        )
        .forEach((el) => {
          el.disabled = true;
        });
  }, [active]);

  const isAddWorkerDataModal = Boolean(
    useSelector((state) => state.modal?.info === null)
  );

  return (
    <>
      <div
        ref={modal}
        style={{
          display:
            docTypes.indexOf(props.previewFile?.fileType) !== -1 &&
            isPressedFile
              ? 'none'
              : 'block',
        }}
        className={
          currentWorker || isPassport || !isAddWorkerDataModal
            ? 'tab__modal'
            : 'tab__modal modalPassport'
        }
      >
        <div className="tab">
          <div className="tab__btn">
            {items.map((n, i) => {
              if (n.page !== 'conditionsWork') {
                return (
                  <button
                    key={`tab-button-${i}`}
                    className={`tablinks ${i === active ? 'active' : ''}`}
                    onClick={openTab}
                    data-index={i}
                  >
                    {n.title}
                  </button>
                );
              } else {
                return (
                  currentWorker && (
                    <button
                      key={`tab-button-${i}`}
                      className={`tablinks ${i === active ? 'active' : ''}`}
                      onClick={openTab}
                      data-index={i}
                    >
                      {n.title}
                    </button>
                  )
                );
              }
            })}
          </div>
          <div
            className="tab__close"
            onClick={() => {
              closeModal('personEdit');
            }}
          >
            <img
              src={close}
              alt="close icon"
            />
          </div>
          <div className="tab__background"></div>
        </div>

        {items[active] && (
          <TabContent
            {...items[active]}
            fullName={fullName}
            typeModal={typeModal}
            typeId={typeId}
            isAddModal={isAddModal}
            setIsWorker={setIsWorker}
            isWorker={isWorker}
            objectId={objectId}
            isPersonal={isPersonal}
            setIsPassport={setIsPassport}
            openFile={() => setIsPressedFile(true)}
            isPressedFile={isPressedFile}
            closeModal={() => closeModal('personEdit')}
            id={id}
            active={active}
            setActive={setActive}
            files={files}
            setFiles={setFiles}
            educationRows={educationRows}
            setEducationRows={setEducationRows}
            workRows={workRows}
            setWorkRows={setWorkRows}
            familyRows={familyRows}
            setFamilyRows={setFamilyRows}
            editSecondaryWorkerInfo={props.editSecondaryWorkerInfo}
          />
        )}
      </div>
      {docTypes.indexOf(props.previewFile?.fileType) !== -1 &&
        isPressedFile && (
          <KadriPreviewDocument
            file={props.previewFile}
            closeModal={() => {
              setIsPressedFile(false);
            }}
          />
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  previewFile: state.workersReducer.previewFile,
  currentWorker: state.currentWorker,
});

KadriCardsPersonEdit = connect(mapStateToProps, {
  setPreviewFile,
  editSecondaryWorkerInfo,
})(KadriCardsPersonEdit);
