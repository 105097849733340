import instance from '../instance';
import loadWorkers from '../actions/workers/loadWorkers';
import {
  setPreviewFile,
  setSearchedWorker,
  setWorkerDocuments,
} from './worker-actions';
import FileDownload from 'js-file-download';
import loadCurrentWorker from '../actions/currentWorker/loadCurrentWorker';
import showAlert from '../actions/alerts/showAlert';

export const uploadDocumentsOld =
  (files, alert, employerId, id, _openNextTab) => async (dispatch) => {
    dispatch(setWorkerDocuments([]));
    try {
      for (let file of files) {
        let formData = new FormData();
        formData.append('sampleFile', file.file);
        formData.append('workerId', employerId);
        formData.append('id', id);
        formData.append('type', file.type);
        const response = await instance.post('/uploadOld', formData, {
          headers: {
            'Content-Type': 'Multipart/form-data',
          },
        });
        if (response.status !== 200) {
          throw Error(response.data || 'Щось пішло не так');
        } else {
          dispatch(getDocuments(id));
          dispatch(loadCurrentWorker(id));
        }
      }

      alert('Документи успішно оновлені', 'success');
    } catch (e) {
      alert(e.message, 'error');
    }
  };

export const getDocuments = (id) => async (dispatch) => {
  try {
    const response = await instance.post('/employer/kadr/getDocuments', { id });
    dispatch(setWorkerDocuments(response.data));
  } catch (e) {
    console.error(e);
  }
};

export const downloadDocument = (filenames, isPreview) => async (dispatch) => {
  for (let filename of filenames) {
    let response = await instance({
      url: `/static/${filename}`,
      method: 'GET',
      responseType: 'blob',
    });
    if (!isPreview) FileDownload(response.data, filename);
    else {
      let reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = function () {
        let base64 = reader.result;
        dispatch(
          setPreviewFile({
            base64,
            fileType: filename.split('.')[filename.split('.').length - 1],
            filename,
          })
        );
      };
    }
  }
};

export const deleteDocument =
  (ids, _workerId) => async (dispatch, getState) => {
    const { workerDocuments } = getState().workersReducer;
    try {
      for (let id of ids) {
        await instance.post('/employer/kadr/delldoc', { id });
      }
      dispatch(
        setWorkerDocuments(
          [...workerDocuments].filter((d) => ids.indexOf(d._id) === -1)
        )
      );
    } catch (e) {
      console.error(e);
    }
  };

export const deleteWorker = (id, alert, employerId) => async (dispatch) => {
  try {
    const response = await instance.post('/employer/deleteOne', { id });
    if (response.status === 200) {
      alert(response.data, 'success');
      dispatch(loadWorkers(employerId, alert));
    } else {
      throw Error(response.data || 'Щось пішло не так');
    }
  } catch (e) {
    dispatch(showAlert(e.message, 'error'));
  }
};

export const addDeletedWorker =
  (body, alert, closeModal) => async (dispatch) => {
    try {
      const response = await instance.post('/employer/addDeleted', body);
      if (response.status === 200) {
        dispatch(setSearchedWorker(null));
        alert(response.data, 'success');
        dispatch(loadWorkers(body.workerid, alert));
        typeof closeModal === 'function' && closeModal();
        return response.data;
      } else {
        throw Error(response.data || 'Щось пішло не так');
      }
    } catch (e) {
      alert(e.message, 'error');
      return Promise.reject(e);
    }
  };

export const acceptEmployer = (body, alert, closeModal) => async (dispatch) => {
  try {
    const response = await instance.post('/employer/kadr/accept', body);
    if (response.status === 200) {
      alert(response.data.message, 'success');
      dispatch(loadWorkers(body.workerId, alert));
      return response.data.fileId;
    } else {
      throw Error(response.data || 'Щось пішло не так');
    }
  } catch (e) {
    alert(e.message, 'error');
    return Promise.reject(e);
  }
};

export const acceptHoliday = (body, alert, closeModal) => async (dispatch) => {
  try {
    const response = await instance.post('/employer/kadr/free', body);
    if (response.status === 200) {
      alert(response.data.message, 'success');
      body?.workerId && dispatch(loadWorkers(body.workerId, alert));
      return response.data.fileId;
    } else {
      throw Error(response.data || 'Щось пішло не так');
    }
  } catch (e) {
    alert(e.message, 'error');
    return Promise.reject(e);
  }
};

export const acceptTruancy = (body, alert, closeModal) => async (dispatch) => {
  try {
    const response = await instance.post('/employer/kadr/progul', body);
    if (response.status === 200) {
      alert(response.data.status, 'success');
      dispatch(loadWorkers(body.workerId, alert));
      return response.data.fileId;
    } else {
      throw Error(response.data || 'Щось пішло не так');
    }
  } catch (e) {
    alert(e.message, 'error');
    return Promise.reject(e);
  }
};

export const acceptDismissal =
  (body, alert, closeModal) => async (dispatch) => {
    try {
      const response = await instance.post('/employer/kadr/uval', body);
      if (response.status === 200) {
        alert(response.data.status, 'success');
        dispatch(loadWorkers(body.workerId, alert));
        return response.data.fileId;
      } else {
        throw Error(response.data || 'Щось пішло не так');
      }
    } catch (e) {
      alert(e.message, 'error');
      return Promise.reject(e);
    }
  };

export const cancellationDismissal = (body, alert) => async (dispatch) => {
  try {
    const response = await instance.post('/employer/kadr/cancellation', body);
    if (response.status === 200) {
      dispatch(loadWorkers(body.workerId, alert));
      alert(response.data, 'success');
    } else {
      throw Error(response.data || 'Щось пішло не так');
    }
  } catch (e) {
    alert(e.message, 'error');
  }
};
