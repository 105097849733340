import React, { useEffect, useMemo, useState } from 'react';
import {
  SButtonLightGreen,
  SButtonLightGrey,
} from '../../../../../styledComponents/Buttons';
import ConditionsHeader from './components/ConditionsHeader';
import '../KadriCards.css';
import './conditions.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import { InputForm } from '../../../../../styledComponents/Inputs';
import { Formik } from 'formik';
import { acceptEmployer } from '../../../../../redux/workers/workers-thunks';
import { useAlert } from 'react-alert';
import datePicker from '../../../../../assets/datePicker.svg';
import { getSettings } from '../../../../../redux/settings/settings-thunks';
import moment from 'moment';
import DatePickerFragment from '../../../../../components/datePicker/datePicker';
import { diffDate } from '../../../../../utilits/diffDate';
import loadCurrentWorker from '../../../../../redux/actions/currentWorker/loadCurrentWorker';
import { deletedDocument } from '../../../../../redux/documentOld/document-thunks';
import { setModalInfo } from '../../../../../redux/modalInfo/modal-actions';
import { getDocumentsJournal } from '../../../../../redux/cadrDocuments/cadrDocuments-thunks';

export let KadriCardsWorkDoc__6 = ({
  closeModal,
  positions,
  acceptEmployer,
  setIndexTab,
  toggleModalList,
  setTypeId,
  setTypeModal,
  openEditWorkerModal,
  employerId,
  isWork__5,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [currentEmployer, setCurrentEmployer] = useState(null);
  const modal = useSelector((state) => state.modal.info);
  const alert = useAlert();
  const dispatch = useDispatch();
  const id = useMemo(
    () =>
      window.location.pathname.slice(
        window.location.pathname.lastIndexOf('/') + 1
      ),
    []
  );

  useEffect(() => {
    document.body.classList.add('body-no-scroll');
    props.getSettings(id);
    dispatch(loadCurrentWorker(employerId, false, false, true)).then((res) => {
      setCurrentEmployer(res);
    });
    return () => {
      document.body.classList.remove('body-no-scroll');
    };
  }, [id, employerId, dispatch]);

  const alertFunc = (message, type) => {
    alert.show(message, { type });
    setIsFetching(false);
  };

  const isValidDate = (d) => d instanceof Date && !isNaN(d);

  return (
    <div>
      {props.settings && (
        <Formik
          enableReinitialize={true}
          initialValues={{
            posada:
              modal?.posada?.name ?? currentEmployer?.postInfo?.posada ?? '',
            beginDate:
              modal?.exp &&
              isValidDate(new Date(moment(modal?.exp.start, 'DD.MM.YYYY')))
                ? new Date(moment(modal?.exp.start, 'DD.MM.YYYY'))
                : null,
            endDate:
              modal?.exp &&
              isValidDate(new Date(moment(modal?.exp.end, 'DD.MM.YYYY')))
                ? new Date(moment(modal?.exp.end, 'DD.MM.YYYY'))
                : null,
            dateConfirm:
              modal?.dataaccept &&
              isValidDate(new Date(moment(modal?.dataaccept, 'DD.MM.YYYY')))
                ? new Date(moment(modal?.dataaccept, 'DD.MM.YYYY'))
                : null,
            firstDayDate:
              modal?.firstday &&
              isValidDate(new Date(moment(modal?.firstday, 'DD.MM.YYYY')))
                ? new Date(moment(modal?.firstday, 'DD.MM.YYYY'))
                : currentEmployer?.postInfo?.workForm
                ? new Date(
                    moment(currentEmployer?.postInfo?.workForm, 'DD.MM.YYYY')
                  )
                : null,
            daysRange: modal?.daysRange ?? 0,
            strokeAlternative: modal?.strokeAlternative ?? '',
            strokeAlternativeTwo: modal?.strokeAlternativeTwo ?? '',
            numberDogovor: modal?.numberDogovor ?? '',
            pilga: modal?.pilga ?? '',
            order:
              modal?.nomer ??
              (props.settings?.number1 !== -1
                ? props.settings?.number1 + 1
                : ''),
            testPeriod: modal?.testperiod ?? '',
            placeWork: modal?.sideofwork || '',
            hours: modal?.hours ?? '',
          }}
          onSubmit={(values) => {
            console.log(values);
            setIsFetching(true);
            const urgentBody = {
              expstart: moment(values.beginDate).format('DD.MM.YYYY'),
              expend: moment(values.endDate).format('DD.MM.YYYY'),
              sideofwork: values.placeWork,
              testperiod: values.testPeriod === 'no' ? 'no' : values.testPeriod,
              type: 'Строковий',
            };
            const notUrgentBody = {
              type: 'Не строковий',
              sideofwork: values.placeWork,
              hours: values.placeWork === 'основне' ? null : values.hours,
            };
            const getBody = () => {
              return isWork__5 ? notUrgentBody : urgentBody;
            };
            let body = {
              isEdit: modal?.isEdit,
              id: modal?.employerId ?? employerId,
              workerId: id,
              posadaname: values.posada,
              posadaid:
                positions.find((p) => p.vacancy === values.posada)?._id ??
                positions[0]._id,
              dataaccept: moment(values.dateConfirm).format('DD.MM.YYYY'),
              firstday: moment(values.firstDayDate).format('DD.MM.YYYY'),
              daysRange: values.daysRange,
              numberDogovor: values.numberDogovor,
              strokeAlternative: values.strokeAlternative,
              strokeAlternativeTwo: values.strokeAlternativeTwo,
              ifPilga: values.pilga.length > 1,
              pilga: values.pilga,
              pilgaprocent: values.pilga,
              nomer: values.order,
              ...getBody(),
            };
            modal && dispatch(deletedDocument(modal.documentId));
            acceptEmployer(body, alertFunc, closeModal).then((fileId) => {
              if (!modal) {
                openEditWorkerModal(employerId);
                setTypeModal('Трудовий договір');
                setIndexTab();
                setTypeId(fileId);
              } else {
                dispatch(setModalInfo(null));
                closeModal();
                dispatch(getDocumentsJournal(modal.year));
              }
            });
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => {
            const isFormValid = useMemo(() => {
              if (isWork__5) {
                return (
                  values.posada &&
                  values.dateConfirm &&
                  values.firstDayDate &&
                  values.pilga &&
                  values.order &&
                  values.placeWork &&
                  (values.placeWork === 'за сумісництвом'
                    ? !!values.hours
                    : true)
                );
              }
              return (
                values.posada &&
                values.beginDate &&
                values.endDate &&
                values.dateConfirm &&
                values.firstDayDate &&
                values.pilga &&
                values.order &&
                values.testPeriod &&
                values.placeWork &&
                (values.placeWork === 'за сумісництвом' ? !!values.hours : true)
              );
            }, [values, isWork__5]);
            const handlePlaceWorkChange = (e) => {
              setFieldValue('placeWork', e.target.value);
              if (e.target.value === 'основне') {
                setFieldValue('hours', '');
              }
            };
            const handleEndDateChange = (data) => {
              setFieldValue('endDate', data);
              setFieldValue(
                'daysRange',
                diffDate(values.beginDate, data, 'days') + 1
              );
            };
            const handleCloseModal = () => {
              document.body.classList.remove('body-no-scroll');
              closeModal();
              if (modal) {
                dispatch(setModalInfo(null));
              }
            };
            const handleFormSubmit = () => {
              if (isFormValid && !isFetching) {
                handleSubmit();
              }
            };
            return (
              <>
                <div className="modal-overlay" />
                <div className="conditions__workdoc">
                  {isFetching && (
                    <div className="disabled__overlay">
                      <div className="lds-ring">
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </div>
                  )}
                  <ConditionsHeader toggle={handleCloseModal}>
                    Умови трудового договору (
                    {isWork__5 ? 'безстрокового' : 'строкового'})
                  </ConditionsHeader>
                  <hr className="conditions__header__hr" />
                  <div className="conditions__content">
                    <div className="conditions__content-left">
                      <p>Посада</p>
                      {!isWork__5 && <p>Строк дії договору</p>}
                      <p>Дата укладення</p>
                      <p>Перший робочий день</p>
                      {!isWork__5 && <p>Більше варіантів "дія договору"</p>}
                      <p>Соціальна пільга</p>
                      <p>Наказ №</p>
                      <p>Договір №</p>
                      {!isWork__5 && <p>Випробувальний строк</p>}
                      <p>Місце роботи</p>
                    </div>
                    <div className="conditions__content-right">
                      <form className="conditions__form">
                        <div className="conditions__row">
                          <select
                            value={values.posada}
                            onChange={handleChange}
                            id="posada"
                            className="conditions__select-input"
                            style={{ width: 314 }}
                          >
                            <option
                              value=""
                              disabled
                              hidden
                            >
                              Виберіть зі списку
                            </option>
                            {positions.map((position, index) => (
                              <option
                                key={index}
                                value={position.vacancy}
                              >
                                {position.vacancy}
                              </option>
                            ))}
                          </select>
                        </div>
                        {!isWork__5 && (
                          <div className="conditions__row">
                            <div className="conditions__calendar">
                              <DatePickerFragment
                                selected={
                                  isValidDate(values.beginDate)
                                    ? values.beginDate
                                    : null
                                }
                                dateFormat="dd.MM.yyyy"
                                placeholderText="Початок"
                                IconGray={
                                  <div
                                    className="item__input__check"
                                    type="button"
                                    style={{
                                      paddingTop: '8px',
                                      marginBottom: '2px',
                                    }}
                                  >
                                    <span>
                                      <img
                                        src={datePicker}
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                }
                                onChange={(data) =>
                                  setFieldValue('beginDate', data)
                                }
                              />
                            </div>
                            <p>-</p>
                            <div className="conditions__calendar">
                              <DatePickerFragment
                                selected={
                                  isValidDate(values.endDate)
                                    ? values.endDate
                                    : null
                                }
                                dateFormat="dd.MM.yyyy"
                                placeholderText="Закінчення"
                                IconGray={
                                  <div
                                    className="item__input__check"
                                    type="button"
                                    style={{
                                      paddingTop: '8px',
                                      marginBottom: '2px',
                                    }}
                                  >
                                    <span>
                                      <img
                                        src={datePicker}
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                }
                                onChange={handleEndDateChange}
                              />
                            </div>
                          </div>
                        )}
                        <div className="conditions__row">
                          <div className="conditions__calendar">
                            <DatePickerFragment
                              selected={
                                isValidDate(values.dateConfirm)
                                  ? values.dateConfirm
                                  : null
                              }
                              dateFormat="dd.MM.yyyy"
                              IconGray={
                                <div
                                  className="item__input__check"
                                  type="button"
                                  style={{
                                    paddingTop: '8px',
                                    marginBottom: '2px',
                                  }}
                                >
                                  <span>
                                    <img
                                      src={datePicker}
                                      alt=""
                                    />
                                  </span>
                                </div>
                              }
                              onChange={(data) =>
                                setFieldValue('dateConfirm', data)
                              }
                            />
                          </div>
                        </div>
                        <div className="conditions__row">
                          <div className="conditions__calendar">
                            <DatePickerFragment
                              selected={
                                isValidDate(values.firstDayDate)
                                  ? values.firstDayDate
                                  : null
                              }
                              dateFormat="dd.MM.yyyy"
                              IconGray={
                                <div
                                  className="item__input__check"
                                  type="button"
                                  style={{
                                    paddingTop: '8px',
                                    marginBottom: '2px',
                                  }}
                                >
                                  <span>
                                    <img
                                      src={datePicker}
                                      alt=""
                                    />
                                  </span>
                                </div>
                              }
                              onChange={(data) =>
                                setFieldValue('firstDayDate', data)
                              }
                            />
                          </div>
                        </div>
                        {!isWork__5 && (
                          <div className="conditions__row">
                            <div className="input__row">
                              <InputForm
                                style={{ background: '#f2f2f2' }}
                                disabled
                                value={values.daysRange}
                                onChange={handleChange}
                                id="daysRange"
                                type="number"
                              />
                              <div className="inputRight">днів</div>
                            </div>
                            <div className="input__row">
                              <InputForm
                                value={values.strokeAlternative}
                                onChange={handleChange}
                                id="strokeAlternative"
                                placeholder="-"
                                type="number"
                              />
                              <div className="inputRight">днів</div>
                            </div>
                            <div className="input__row">
                              <InputForm
                                value={values.strokeAlternativeTwo}
                                onChange={handleChange}
                                id="strokeAlternativeTwo"
                                placeholder="-"
                                type="number"
                              />
                              <div className="inputRight">днів</div>
                            </div>
                          </div>
                        )}
                        <div className="conditions__row">
                          <select
                            value={values.pilga}
                            onChange={handleChange}
                            id="pilga"
                            style={{ width: '314px' }}
                            className="conditions__select-input"
                          >
                            <option
                              value=""
                              disabled
                              hidden
                            >
                              Виберіть зі списку
                            </option>
                            <option value=" ">пільга не застосовується</option>
                            <option value="50">50%</option>
                            <option value="100">100%</option>
                            <option value="150">150%</option>
                            <option value="200">200%</option>
                            <option value="300">300%</option>
                            <option value="400">400%</option>
                            <option value="500">500%</option>
                          </select>
                        </div>
                        <div className="conditions__row">
                          <InputForm
                            value={values.order}
                            onChange={handleChange}
                            id="order"
                            type="text"
                          />
                        </div>
                        <div className="conditions__row">
                          <InputForm
                            value={values.numberDogovor}
                            onChange={handleChange}
                            id="numberDogovor"
                            type="number"
                            placeholder="Введіть номер договору"
                          />
                        </div>
                        {!isWork__5 && (
                          <div className="conditions__row">
                            <select
                              value={values.testPeriod}
                              onChange={handleChange}
                              id="testPeriod"
                              className="conditions__select-input"
                              style={{ width: '143px', padding: '1px' }}
                            >
                              <option
                                value=""
                                disabled
                                hidden
                              >
                                Виберіть зі списку
                              </option>
                              <option value="no">Ні</option>
                              <option value="1">1 місяць</option>
                              <option value="2">2 місяці</option>
                              <option value="3">3 місяці</option>
                            </select>
                          </div>
                        )}
                        <div className="conditions__row">
                          <select
                            value={values.placeWork}
                            onChange={handlePlaceWorkChange}
                            id="placeWork"
                            className="conditions__select-input"
                            defaultValue={''}
                            style={{ width: '50%', marginRight: '20px' }}
                          >
                            <option
                              value=""
                              disabled
                              hidden
                            >
                              Виберіть зі списку
                            </option>
                            <option value="основне">Основне</option>
                            <option value="за сумісництвом">
                              За сумісництвом
                            </option>
                          </select>
                          {values.placeWork === 'за сумісництвом' && (
                            <InputForm
                              value={values.hours}
                              onChange={handleChange}
                              id="hours"
                              type="number"
                              style={{ width: '30%' }}
                              placeholder="год/день"
                            />
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="conditions__buttons">
                    {(values.placeWork !== 'за сумісництвом' || values.hours) &&
                    isFormValid &&
                    !isFetching ? (
                      <SButtonLightGreen onClick={handleSubmit}>
                        Зберегти
                      </SButtonLightGreen>
                    ) : (
                      <SButtonLightGrey>Зберегти</SButtonLightGrey>
                    )}
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  positions: state.cadrDocumentsReducer.positions,
  settings: state.settingsReducer.settings,
});

KadriCardsWorkDoc__6 = connect(mapStateToProps, {
  acceptEmployer,
  getSettings,
})(KadriCardsWorkDoc__6);

export default KadriCardsWorkDoc__6;
