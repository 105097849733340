import instance from '../redux/instance';
import { getCookie } from '../utilits/cookies';
import axios from 'axios';
import {
  GET_KADRI_DOC,
  GOOGLE_FILE_DELETE,
  GOOGLE_FILE_DOWNLOAD,
  GOOGLE_FILE_VIEW,
  mainURL,
} from '../constants/urls';

export const getDocKadri = async (id) => {
  const token = getCookie('token');
  const res = await axios.post(
    GET_KADRI_DOC,
    { indexId: id },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );

  return res.data;
};

export const uploadDocumentsKadri = async (
  files,
  id,
  values,
  fullName,
  alert
) => {
  let pathBasket = `Kadri/${fullName}/${values.surname}_${values.name}_${values.patronymic}/`;

  try {
    let count = 1;
    for (let file of files) {
      let formData = new FormData();

      formData.append('filename', file.file.name);
      formData.append('sampleFile', file.file);
      formData.append('indexId', id);
      formData.append('path', pathBasket);
      formData.append('type', file.type);
      formData.append('info', JSON.stringify(file.info));

      const token = getCookie('token');

      const response = await axios.post(
        `${mainURL}/googleStorage/upload`,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log('mainURL', mainURL);

      console.log('response', response);

      if (response.status !== 200) {
        throw Error(response.data || 'Щось пішло не так');
      } else {
        alert(`Документ №${count++} доданий`, { type: 'success' });
        await getDocKadri(id);
      }
    }
  } catch (e) {
    alert(e.message, { type: 'error' });
  }
};

export const deleteFileKadri = async (id, path, filename, elId) => {
  const token = getCookie('token');
  const res = await axios.post(
    GOOGLE_FILE_DELETE,
    { id: id, path: path, filename: filename, elId: elId },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  return res.data;
};
export const downloadFileKadri = async (id, path, filename) => {
  const token = getCookie('token');
  const res = await axios.post(
    GOOGLE_FILE_DOWNLOAD,
    { id: id, path: path, filename: filename },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  return res.data;
};
export const viewFilesKadri = async (id, path, filename) => {
  const token = getCookie('token');
  const res = await axios.post(
    GOOGLE_FILE_VIEW,
    { id, path, filename },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  );
  console.log('viewFilesKadri :: res.data >>>', res.data);

  return res.data;
};
