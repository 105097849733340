import React, { useEffect, useState } from 'react';
import deleteIcon from '../../images/icons/delete.svg';

import EditRent from './EditRent';
import { SpaceBetween } from '../../styledComponents/Layout';
import SearchInput from '../../components/Inputs/SearchInput/SearchInput';
import { WarningPopUp } from '../PopUps/WarningPopup';
import Table from '../../components/tables/Table';
import TableCell from '../../components/tables/TableCell/TableCell';
import TableRow from '../../components/tables/TableRow/TableRow';
import { TextGrey } from '../../styledComponents/Text';
import GreenButton from '../../components/buttons/GreenBuuton.js/GreenButton';
import { useAlert } from 'react-alert';
import { deleteObject, updateMonthProlongation } from '../../api/objects';
import regions from '../../constants/regions';
import typeObject from '../../constants/typeObject';
import { getNowDate } from '../../utilits/getNowDate';
import { diffDate } from '../../utilits/diffDate';
import ProgressBar from '../../components/progress/progressBar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import loadObjects from '../../redux/actions/objects/loadObjects';
import updateObject from '../../redux/actions/objects/updateObject';
import createObjects from '../../redux/actions/objects/createObject';
import addressPlace from '../../utilits/addressPlace';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Loader from '../../components/loaders/Loader';

const GRID_TEMPLATE = '15fr 40fr 15fr 100fr 50fr 40fr 218px 40fr';
const GRID_PLACEHOLDER = '100%';

const TypeObject = ({ object }) => {
  if (!object) {
    return '<не вказаний>';
  }
  return typeObject[object]?.name || object;
};

const ObjectRegion = ({ region }) => {
  if (!region) {
    return '<не вказаний>';
  }
  return regions[region]?.name || region;
};

const RentRow = ({
  index,
  object,
  setDeleteItem,
  setEditObject,
  setActiveModal,
  setPressedId,
  pressedId,
}) => {
  const { typeObject, dateEnd, dateStart, typeDocStatus, _id, prolongation } =
    object;
  const dayTreaty = diffDate(new Date(), dateEnd, 'days');
  const dayFull = diffDate(dateStart, dateEnd, 'days');

  useEffect(() => {
    const prolongationCheck = async () => {
      try {
        if (dayTreaty <= 0 && prolongation === true) {
          await updateMonthProlongation(_id);
        }
      } catch (error) {
        console.error(
          "Помилка при оновленні місячної пролонгації для об'єкта з id:",
          _id,
          error
        );
      }
    };
    prolongationCheck();
  }, [dayTreaty, prolongation, _id]);

  const colorProgress = {
    success: '#2bc572',
    warning: '#f2c94c',
    error: '#eb5757',
  };

  const infoStatus = () => {
    let isOffice = object.typeObject === 'ОФС';
    let personalDate = object.docPersonal
      ? moment().diff(new Date(object.docPersonal.date), 'days')
      : false;

    let arrayText = [
      `Відсутня відповідальна особа типу "адміністратор/ зам/ завідуючий складом"`,
      'Відсутні файли у папці "договір оренди/суборенди"',
      'Протягом 30 днів не було створено жодного договору підряду',
    ];
    let arrayBoolean = [
      !isOffice && object.manager.length === 0,
      !typeDocStatus,
      personalDate >= 30 || !object.docPersonal,
    ];

    if (
      (isOffice || object.manager.length !== 0) &&
      typeDocStatus &&
      personalDate < 30 &&
      object.docPersonal
    ) {
      return null;
    }

    return arrayBoolean.map((el, i) => {
      return el && <li key={i}>{arrayText[i]}</li>;
    });
  };

  return (
    <div
      onClick={() => {
        setEditObject(object);
        setPressedId(_id);
      }}
    >
      <TableRow
        gridTemplate={GRID_TEMPLATE}
        isPressed={pressedId === _id}
      >
        <TableCell justify="center">{index + 1}</TableCell>
        <TableCell>
          <ObjectRegion region={object.region} />
        </TableCell>
        <TableCell>{object.code ? object.code : '-'}</TableCell>
        <TableCell>
          {!object.place?.street
            ? 'вулиця відсутня'
            : addressPlace(
                {
                  city: object.place?.city,
                  street: object.place?.street,
                  street_number:
                    object.houseNumber && object.place?.street_number,
                  isNameCity: false,
                },
                object.houseNumber
              )
                .join(', ')
                .replace('будинок ', '')}
        </TableCell>

        <TableCell justify="center">
          {infoStatus() && infoStatus().some((el) => el !== false) ? (
            <>
              <div
                data-tip
                data-for={`status${index}`}
                style={{
                  width: 30,
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '16px',
                }}
              >
                ⚠️
              </div>
              <ReactTooltip
                id={`status${index}`}
                effect={'solid'}
                backgroundColor={'#52616E'}
              >
                <ul>{infoStatus()}</ul>
              </ReactTooltip>
            </>
          ) : (
            ''
          )}
        </TableCell>

        <TableCell>
          <TypeObject object={typeObject} />
        </TableCell>
        <TableCell width="100%">
          <ProgressBar
            bgcolor={
              Math.round((dayTreaty / dayFull) * 100) >= 39
                ? (dayTreaty / dayFull) * 100 >= 48
                  ? colorProgress.success
                  : colorProgress.warning
                : colorProgress.error
            }
            completed={
              dayTreaty + 1 < 0 ? 0 : Math.round((dayTreaty / dayFull) * 100)
            }
            number={dayTreaty + 1 < 0 ? 0 : dayTreaty + 1}
          />
        </TableCell>
        <TableCell justify="center">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setDeleteItem({ ...object, index: index + 1 });
              setActiveModal(true);
            }}
          >
            <img
              src={deleteIcon}
              alt="delete"
            />
          </button>
        </TableCell>
      </TableRow>
    </div>
  );
};

const PlaceholderRow = ({ children }) => {
  return (
    <TableRow
      type="header"
      gridTemplate={GRID_PLACEHOLDER}
    >
      <TableCell style={{ justifyContent: 'center' }}>{children}</TableCell>
    </TableRow>
  );
};

const matches = (needle, haystack) => {
  needle = needle.toLocaleLowerCase();
  haystack = (haystack || '').toLocaleLowerCase();
  return haystack.includes(needle);
};

const deleteView = (object) => `№${object.index}: ${object.reference}`;

const defaultObject = {
  reference: getNowDate(),
  address: '',
  place: null,
  houseNumber: '',
  position: [],
  email: '',
  code: '',
  region: '',
  typeObject: '',
  typeContract: '',
  prolongation: false,
  dateEnd: '',
  dateStart: '',
};

export const Rent = () => {
  const object = useSelector((state) => state.object);
  const user = useSelector((state) => state.user);
  const [search, setSearch] = React.useState('');
  const [isLoading, setLoading] = useState(false);
  const [deleteItem, setDeleteItem] = React.useState(null);
  const [editObject, setEditObject] = React.useState(null);
  const [pressedId, setPressedId] = useState(null);
  const [activeModal, setActiveModal] = useState(false);
  const [indexTab, setIndexTab] = useState(0);
  const dispatch = useDispatch();
  let history = useHistory();
  const alert = useAlert();

  const updateObjects = async () => {
    try {
      await dispatch(loadObjects(alert));
      setLoading(true);
    } catch (error) {
      console.error("Помилка при завантаженні об'єктів:", error);
      alert.show("Сталася помилка при завантаженні об'єктів", {
        type: 'error',
      });
    }
  };

  useEffect(() => {
    updateObjects();
  }, []);

  useEffect(() => {
    try {
      if (
        history.location.state &&
        history.location.state.location === 'kadr'
      ) {
        setEditObject(history.location.state.obj);
        setIndexTab(4);
      }
    } catch (error) {
      console.error(
        'Помилка при обробці параметрів маршруту (history.location.state):',
        error
      );
    }
  }, [history.location.state]);

  let filtered = object || [];

  if (filtered.length > 0 && search.length > 0) {
    const fieldsArray = [
      (item) => item?.userInfO?.full_name,
      (item) => item?.rnokpp,
    ];
    const fields = [
      (item) => item.reference,
      (item) => item.address,
      (item) => item.code,
      (item) => regions[item.region]?.name,
      (item) => typeObject[item.typeObject]?.name,
      (item) =>
        item.lessee
          .map(
            (el) =>
              fieldsArray.some((field) => matches(search, field(el))) &&
              el?.userInfO?.full_name
          )
          .filter((el) => el)[0],
      (item) =>
        item.lessee
          .map(
            (el) =>
              fieldsArray.some((field) => matches(search, field(el))) &&
              el?.rnokpp
          )
          .filter((el) => el)[0],
      (item) =>
        item.landlord
          .map(
            (el) =>
              fieldsArray.some((field) => matches(search, field(el))) &&
              el?.userInfO?.full_name
          )
          .filter((el) => el)[0],
      (item) =>
        item.landlord
          .map(
            (el) =>
              fieldsArray.some((field) => matches(search, field(el))) &&
              el?.rnokpp
          )
          .filter((el) => el)[0],
    ];

    filtered = filtered.filter((item) =>
      fields.some((field) => matches(search, field(item)))
    );
  }

  if (user.userType === 'regionalManager') {
    filtered = filtered.filter((item) =>
      typeof user.region === 'string'
        ? user.region === item.region
        : user.region.some((el) => el === item.region)
    );
  }

  const saveObject = async (objectData) => {
    try {
      const { _id } = objectData;

      if (!objectData.position) {
        objectData.position = [];
      }

      let response;
      if (_id) {
        response = await dispatch(updateObject(_id, objectData, setLoading));
      } else {
        response = await dispatch(createObjects(objectData));
      }

      if (response && response.data) {
        const { success, message } = response.data;

        if (success) {
          await dispatch(loadObjects());
          alert.show(message || "Об'єкт успішно збережено", {
            type: 'success',
          });
          return { success: true };
        } else {
          alert.show(message || "Помилка при збереженні об'єкта", {
            type: 'error',
          });
          return { success: false, message };
        }
      } else {
        throw new Error('Неочікувана відповідь від сервера');
      }
    } catch (error) {
      console.error("Помилка при збереженні об'єкта:", error);
      alert.show(error.message || "Помилка при збереженні об'єкта", {
        type: 'error',
      });
      return { success: false, message: error.message };
    }
  };

  return (
    <div>
      <SpaceBetween>
        <TextGrey>Об'єкт діяльності</TextGrey>
        <SearchInput
          placeholder="Пошук"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          clearInputFunc={() => setSearch('')}
        />
        <div style={{ position: 'relative' }}>
          <GreenButton onClick={() => setEditObject(defaultObject)}>
            <span>+ Додати об'єкт</span>
          </GreenButton>
        </div>
      </SpaceBetween>

      <Table className="table-rent">
        <TableRow
          type="header"
          gridTemplate={GRID_TEMPLATE}
        >
          <TableCell justify="center">№</TableCell>
          <TableCell>Регіон</TableCell>
          <TableCell>Код</TableCell>
          <TableCell>Адреса</TableCell>
          <TableCell justify="center">Статус</TableCell>
          <TableCell>Тип</TableCell>
          <TableCell>Строк дії договору</TableCell>
          <TableCell justify="center">Видалити</TableCell>
        </TableRow>

        {isLoading ? (
          filtered?.length ? (
            filtered.map((obj, index) => (
              <RentRow
                key={obj._id || index}
                index={index}
                object={obj}
                setDeleteItem={setDeleteItem}
                setActiveModal={setActiveModal}
                setEditObject={setEditObject}
                pressedId={pressedId}
                setPressedId={setPressedId}
              />
            ))
          ) : (
            <PlaceholderRow>Немає користувачів</PlaceholderRow>
          )
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 100,
            }}
          >
            <Loader style={{ transform: 'scale(3)' }} />
          </div>
        )}
      </Table>

      {(activeModal || editObject || deleteItem) && (
        <div className="bg__layer" />
      )}

      {deleteItem && (
        <WarningPopUp
          onDelete={async () => {
            try {
              await deleteObject(deleteItem._id);
              updateObjects();
              setDeleteItem(null);
              setEditObject(null);
              setActiveModal(false);
            } catch (error) {
              console.error(
                "Помилка при видаленні об'єкта з id:",
                deleteItem._id,
                error
              );
              alert.show("Сталася помилка при видаленні об'єкта", {
                type: 'error',
              });
            }
          }}
          toggleWarningModal={() => {
            setDeleteItem(null);
            setEditObject(null);
            setActiveModal(false);
          }}
          name={deleteView(deleteItem)}
        />
      )}

      {!activeModal && editObject && (
        <EditRent
          object={filtered}
          indexPageTab={indexTab}
          user={user}
          setEditObject={setEditObject}
          objectData={editObject}
          setActiveModal={setActiveModal}
          saveObject={saveObject}
        />
      )}
    </div>
  );
};
