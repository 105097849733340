import React, { useEffect, useState } from 'react';
import IconEngineer from '../assets/icon/engineering.svg';
import IconContractor from '../assets/icon/contractors.svg';
import IconDoc from '../assets/icon/doc.svg';
import { getPersonal, getPersonalEmployerAll } from '../../../api/personal';
import { SButtonGrey } from '../../../styledComponents/Buttons';
import { InputForm } from '../../../styledComponents/Inputs';
import { useFormik } from 'formik';
import ErrorMessageAll from '../../../utilits/errorMessageAll';
import CustomAllSelect from '../../../components/selectCustom/customAllSelect';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  getPositions,
  getAllPositions,
} from '../../../redux/cadrDocuments/cadrDocuments-thunks';
import trash from '../../../assets/trash.svg';

const EdiRentStatistic = ({
  id,
  landlordValue,
  docPersonal,
  setFieldValue,
  update,
  positionList,
}) => {
  const [personalCount, setPersonalCount] = useState(null);
  const [workCount, setWorkCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const positions = useSelector(
    (state) => state.cadrDocumentsReducer.positions
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        if (id) {
          const personalData = await getPersonal(id);
          setPersonalCount(personalData.length);
        }

        if (landlordValue && landlordValue.length > 0) {
          const res = await getPersonalEmployerAll(landlordValue);
          setWorkCount(res.employers.length);
          await dispatch(getPositions(landlordValue[0]._id));
        } else {
          await dispatch(getAllPositions());
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Помилка при завантаженні даних статистики:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, landlordValue, dispatch]);

  const {
    values,
    touched,
    errors,
    setFieldValue: newSet,
    handleSubmit,
  } = useFormik({
    initialValues: {
      positionList: positionList ? positionList : [],
    },
    onSubmit: (values) => {
      setFieldValue('position', values.positionList);
      update();
    },
  });

  useEffect(() => {
    setFieldValue('position', values.positionList);

    if (
      values.positionList &&
      values.positionList.length > 0 &&
      JSON.stringify(positionList) !== JSON.stringify(values.positionList)
    ) {
      const saveTimer = setTimeout(() => {
        update();
      }, 1000);

      return () => clearTimeout(saveTimer);
    }
  }, [values.positionList, setFieldValue, positionList, update]);

  return (
    <div className="statistic__container">
      {isLoading && (
        <div className="disabled__overlay">
          <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
      )}
      <div className="state">
        <h4>Штатний розпис</h4>
        <SButtonGrey
          onClick={(e) => {
            e.preventDefault();

            const defaultPosition =
              positions.length > 0
                ? {
                    position: {
                      label: positions[0].vacancy,
                      value: positions[0]._id,
                    },
                    quality: 0,
                  }
                : {
                    position: null,
                    quality: 0,
                  };

            newSet('positionList', [...values.positionList, defaultPosition]);
          }}
        >
          + Додати
        </SButtonGrey>
        <form
          action="#"
          method="post"
          className="state__form"
        >
          {values.positionList.map((el, i) => (
            <div
              key={i}
              className="state__column"
            >
              <div
                className="state__row"
                style={{ minWidth: '300px', maxWidth: 300 }}
              >
                <label htmlFor="region">Назва посади</label>
                <div className="edit_rent__wrapper">
                  <CustomAllSelect
                    placeholder={'Виберіть посаду'}
                    name="namePosition"
                    isChange={false}
                    changeFn={(data) => {
                      if (!data || !data.label || !data.value) {
                        console.error(
                          'Невірний формат даних для позиції:',
                          data
                        );
                        return;
                      }

                      newSet(
                        'positionList',
                        values.positionList.map((el, k) => {
                          if (k === i) {
                            return {
                              position: {
                                label: data.label,
                                value: data.value,
                              },
                              quality: el.quality || 0,
                            };
                          } else {
                            return el;
                          }
                        })
                      );
                    }}
                    defaultValue={
                      el.position ||
                      (positions.length > 0
                        ? {
                            value: positions[0]._id,
                            label: positions[0].vacancy,
                          }
                        : null)
                    }
                    options={positions.map((el) => {
                      return {
                        value: el._id,
                        label: el.vacancy,
                      };
                    })}
                  />
                  <ErrorMessageAll
                    name="namePosition"
                    touched={touched}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="state__row">
                <label htmlFor="region">Кількість</label>
                <div className="edit_rent__wrapper">
                  <InputForm
                    value={
                      el.quality === 0 && touched[`quality_${i}`]
                        ? ''
                        : el.quality || 0
                    }
                    style={{
                      width: 60,
                      textAlign: 'center',
                    }}
                    placeholder="Введіть кількість"
                    name="quality"
                    type="number"
                    onChange={(data) => {
                      touched[`quality_${i}`] = true;

                      const numValue =
                        data.target.value === ''
                          ? 0
                          : parseInt(data.target.value) || 0;

                      newSet(
                        'positionList',
                        values.positionList.map((item, k) => {
                          if (k === i) {
                            return {
                              position: item.position,
                              quality: numValue,
                            };
                          } else {
                            return item;
                          }
                        })
                      );
                    }}
                    onFocus={() => {
                      touched[`quality_${i}`] = true;
                    }}
                  />
                  <ErrorMessageAll
                    name="quality"
                    touched={touched}
                    errors={errors}
                  />
                </div>
              </div>
              <div
                className="quota__row__table__trash"
                style={{ width: '32px', height: '45px' }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    newSet(
                      'positionList',
                      values.positionList.filter((item, j) => i !== j)
                    );
                  }}
                >
                  <img
                    src={trash}
                    alt="Видалити"
                  />
                </button>
              </div>
            </div>
          ))}
        </form>
      </div>
      <div className="statistic__row">
        <div className="statistic__box statistic__box-flex">
          <div className="statistic__img">
            <img
              src={IconEngineer}
              alt="Інженери"
            />
          </div>
          <div className="statistic__info">
            <p>Працівників</p>
            <span>{workCount ? workCount : 0}</span>
          </div>
        </div>
        <div className="statistic__box statistic__box-flex">
          <div className="statistic__img">
            <img
              src={IconContractor}
              alt="Підрядники"
            />
          </div>
          <div className="statistic__info">
            <p>Підрядників</p>
            <span>{personalCount ? personalCount : 0}</span>
          </div>
        </div>
        <div className="statistic__box statistic__box-flex">
          <div className="statistic__img">
            <img
              src={IconDoc}
              alt="Документи"
            />
          </div>
          <div className="statistic__info">
            <p>Створено договорів підряду за рік</p>
            <span>{docPersonal ? docPersonal.count : 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EdiRentStatistic;
